<div class=align-container>
	<div class="logo-container"><img src="/assets/img/ufcw1262-logo.png" /></div>
	<div class="text-container" *ngIf="!showSuccess">
		To complete your UFCW Local1262 application, enter your name and select a preferred communication method. After the form is submitted, you will receive a link to the Membership Application.
	</div>
	<div class="text-container" *ngIf="showSuccess">
		Success! You should receive an e-mail shortly. If you do not receive an e-mail, check your junk mail.
	</div>
	<div class="form-container" [ngClass]="{'hidden': loading || showSuccess }">
		<form #f="ngForm" [formGroup]="form" class="form">
			<div class="form-row">
				<label for="name">Full name:</label>
				<input type="input" class="form-control" id="name" formControlName="name">
			</div>
			<div class="form-row">
				<label for="type">Communication method:</label>
				<select class="form-control" id="type" formControlName="type">
					<option></option>
					<option value="email">E-mail</option>
					<option value="phone">Mobile phone</option>
				</select>
				<i class="fas fa-caret-down"></i>
			</div>
			<div *ngIf="form.getRawValue()['type'] === 'email'" class="form-row">
				<label for="email">E-mail address:</label>
				<input type="input" class="form-control" id="email" formControlName="email">
			</div>
			<div *ngIf="form.getRawValue()['type'] === 'phone'" class="form-row">
				<label for="phoneNumber">Mobile phone number:</label>
				<input type="input" class="form-control" appPhoneMask id="phoneNumber" formControlName="phoneNumber">
			</div>
			<div class="button-container">
				<button type="button" class="btn btn-md btn-primary" (click)="submit()" [disabled]="!form.valid">Submit</button>
			</div>
		</form>
	</div>
	<div class="loading-container" *ngIf="loading">
		<div class="loading in"></div>
	</div>
</div>