import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class FormService {

	constructor(
		private http: HttpClient,
	) { }

	getFormLink(formRequest: any) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const params = new HttpParams();

		return this.http.post(`/api/form`, JSON.stringify(formRequest), { headers, params, responseType: 'text' });
	}

	ping() {
		const headers = new HttpHeaders();

		return this.http.get(`api/ping`, { headers });
	}
}
