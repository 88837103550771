import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
	showLogin = true;
	routerSubscription: Subscription;

	constructor(
		private router: Router
	) { }

	ngOnInit() {
		this.routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (this.router.url.indexOf('/login') > -1 || this.router.url === '/') {
					this.showLogin = true;
				} else {
					this.showLogin = false;
				}
			}
		});
	}

	ngOnDestroy() {
		if (this.routerSubscription) {
			this.routerSubscription.unsubscribe();
		}
	}
}
