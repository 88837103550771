import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { customToastrComponent } from './custom-toastr/custom-toastr.component';
import { HomeComponent } from './home/home.component';
import { FormService } from './services/form.service';
import { PhoneMaskDirective } from './shared/phone.mask.directive';

@NgModule({
	declarations: [
		AppComponent,
		customToastrComponent,
		HomeComponent,
		PhoneMaskDirective
	],
	providers: [
		FormService,
		BsModalService,
		{ provide: LocationStrategy, useClass: HashLocationStrategy }
	],
	bootstrap: [AppComponent],
	imports: [
		CommonModule,
		FormsModule,
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		NgSelectModule,
		MatTableModule,
		MatSortModule,
		ModalModule.forRoot(),
		ToastrModule.forRoot({
			positionClass: 'container-alerts',
			toastClass: 'alert',
			closeButton: true,
			progressBar: true,
			progressAnimation: 'decreasing',
			timeOut: 2500,
			extendedTimeOut: 2500,
			tapToDismiss: true,
			iconClasses: {
				error: 'alert-danger',
				info: 'alert-info',
				success: 'alert-success',
				warning: 'alert-warning'
			},
			toastComponent: customToastrComponent
		})
	]
})
export class AppModule { }
