import { Component } from "@angular/core";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";

import {
    animate,
    keyframes,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

@Component({
    selector: "app-custom-toastr",
    templateUrl: "./custom-toastr.component.html",
    styleUrls: ["./custom-toastr.component.css"],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0,
            })),
            transition('inactive => active', animate('350ms ease-out', keyframes([
                style({
                    transform: 'translate3d(0, -70px, 0) skewY(0deg)',
                    opacity: 0,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('350ms ease-out', keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    transform: 'translate3d(0, -70px, 0) skewY(0deg)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ]
})
export class customToastrComponent extends Toast {

    constructor(protected override toastrService: ToastrService, public override toastPackage: ToastPackage) {
        super(toastrService, toastPackage);
    }
}