import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormService } from '../services/form.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit {
	loading = false;
	showSuccess = false;

	form: UntypedFormGroup;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private formService: FormService,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			'unionStoreNumber': new UntypedFormControl('', [Validators.required]),
			'employerEmail': new UntypedFormControl('', [Validators.required]),
			'type': new UntypedFormControl('', [Validators.required]),
			'email': new UntypedFormControl('', [Validators.email]),
			'phoneNumber': new UntypedFormControl('', []),
			'name': new UntypedFormControl('', [Validators.required]),
		}, {
			validators: [
				this.checkTypeField()
			]
		});

		this.route.queryParams.subscribe(params => {
			if (params['unionStoreNumber']) {
				this.form.controls['unionStoreNumber'].setValue(params['unionStoreNumber'], { emitEvent: true });
			}
			if (params['employerEmail']) {
				this.form.controls['employerEmail'].setValue(params['employerEmail'], { emitEvent: true });
			}
		});
	}

	checkTypeField() {
		return (group: UntypedFormGroup): { [key: string]: any } => {
			let valid = false;
			if (this.form) {
				valid = (this.form.getRawValue()['type'] === 'email' && this.form.getRawValue()['email']) || (this.form.getRawValue()['type'] === 'phone' && this.form.getRawValue()['phoneNumber'])
			}

			if (valid) {
				group.controls['phoneNumber'].setErrors(null);
				return {};
			} else {
				group.controls['phoneNumber'].setErrors(Validators);
				return {
					missing: true
				};
			}
		};
	}

	submit() {
		this.loading = true;
		const formValues = this.form.getRawValue();
		if (formValues['phoneNumber']) {
			formValues['phoneNumber'] = formValues['phoneNumber'].replace(/\D/g, '');
		}

		this.formService.getFormLink(formValues).subscribe((result) => {
			if (formValues['type'] === 'email') {
				this.showSuccess = true;
				this.loading = false;
			} else {
				window.location.href = result;
			}
		});
	}

}
